import { FuseNavigation } from '@fuse/types';

export const navigation_admin: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : '',
        translate: '',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id       : 'brands',
                title    : 'Dashboard(fazer)',
                translate: 'Dashboard(fazer)',
                type     : 'item',
                icon     : 'home',
                url      : '/apps/brands'
            },
            {
                id       : 'brands',
                title    : 'Marcas',
                translate: 'Marcas',
                type     : 'item',
                icon     : 'home',
                url      : '/apps/brands'
            },
            {
                id       : 'negotiations',
                title    : 'Negociações',
                translate: 'Negociações',
                type     : 'collapsable',
                icon     : 'work',
                children : [
                    {
                        id   : 'all',
                        title: 'Todas',
                        type : 'item',
                        url  : '/apps/negotiations/all'
                    }
                    ,
                    {
                        id   : 'open',
                        title: 'Abertas',
                        type : 'item',
                        url  : '/apps/negotiations/open'
                    },
                    {
                        id   : 'finished',
                        title: 'Finalizadas',
                        type : 'item',
                        url  : '/apps/negotiations/finished'
                    },
                    {
                        id   : 'sorting',
                        title: 'Para a Triagem',
                        type : 'item',
                        url  : '/apps/negotiations/sorting'
                    },
                    {
                        id   : 'phases',
                        title: 'Etapas',
                        type : 'item',
                        icon : 'view_compact',
                        url  : '/apps/settings/phases'
                    }
                ]
            },
            {
                id       : 'investors',
                title    : 'Investidores',
                translate: 'Investidores',
                type     : 'item',
                icon     : 'today',
                url      : '/apps/investors'
            },
            {
                id       : 'sellers',
                title    : 'Vendedores',
                translate: 'Vendedores',
                type     : 'item',
                icon     : 'supervised_user_circle',
                url      : '/apps/sellers'
            },
            {
                id       : 'reports',
                title    : 'Relatórios',
                translate: 'Relatórios',
                type     : 'item',
                icon     : 'pie_chart',
                url      : '/apps/reports'
            },
            {
                id       : 'reports',
                title    : 'Grupo de tarefas (fazer)',
                translate: 'Grupo de tarefas (fazer)',
                type     : 'item',
                icon     : 'pie_chart',
                url      : '/apps/reports'
            },

            // {
            //     id       : 'settings',
            //     title    : 'Configurações',
            //     translate: 'Configurações',
            //     type     : 'collapsable',
            //     icon     : 'settings_applications',
            //     children : [
            //         {
            //             id   : 'models-email',
            //             title: 'Modelos de e-mail',
            //             type : 'item',
            //             icon : 'email',
            //             url  : '/apps/settings/models-email'
            //         },
            //         {
            //             id   : 'channels',
            //             title: 'Canais',
            //             type : 'item',
            //             icon : 'device_hub',
            //             url  : '/apps/settings/channels'
            //         },
            //         {
            //             id   : 'modalities',
            //             title: 'Modalidades',
            //             type : 'item',
            //             icon : 'local_offer',
            //             url  : '/apps/settings/modalities'
            //         }
            //     ]
            // },

            {
                id       : 'profile',
                title    : 'Perfil',
                translate: 'Perfil',
                type     : 'item',
                icon     : 'supervised_user_circle',
                url      : '/apps/profile'
            },

            {
                id       : 'system-support',
                title    : 'Suporte do Sistema',
                translate: 'Suporte do Sistema',
                type     : 'item',
                icon     : 'supervised_user_circle',
                url      : '/apps/system-support'
            }
        ]
    }
];
export const navigation_seller: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : '',
        translate: '',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id       : 'negotiations',
                title    : 'Negociações',
                translate: 'Negociações',
                type     : 'collapsable',
                icon     : 'work',
                children : [
                    {
                        id   : 'all',
                        title: 'Todas',
                        type : 'item',
                        url  : '/apps/negotiations/all'
                    }
                    ,
                    {
                        id   : 'open',
                        title: 'Abertas',
                        type : 'item',
                        url  : '/apps/negotiations/open'
                    },
                    {
                        id   : 'finished',
                        title: 'Finalizadas',
                        type : 'item',
                        url  : '/apps/negotiations/finished'
                    },
                    {
                        id   : 'sorting',
                        title: 'Para a Triagem',
                        type : 'item',
                        url  : '/apps/negotiations/sorting'
                    },
                     {
                        id   : 'phases',
                        title: 'Etapas',
                        type : 'item',
                        icon : 'view_compact',
                        url  : '/apps/settings/phases'
                    },
                ]
            },
            {
                id       : 'investors',
                title    : 'Investidores',
                translate: 'Investidores',
                type     : 'item',
                icon     : 'today',
                url      : '/apps/investors'
            },

            {
                id       : 'settings',
                title    : 'Configurações',
                translate: 'Configurações',
                type     : 'collapsable',
                icon     : 'settings_applications',
                children : [
                    {
                        id   : 'models-email',
                        title: 'Modelos de e-mail',
                        type : 'item',
                        icon : 'email',
                        url  : '/apps/settings/models-email'
                    },
                    {
                        id   : 'channels',
                        title: 'Canais',
                        type : 'item',
                        icon : 'device_hub',
                        url  : '/apps/settings/channels'
                    },
                    {
                        id   : 'modalities',
                        title: 'Modalidades',
                        type : 'item',
                        icon : 'local_offer',
                        url  : '/apps/settings/modalities'
                    }
                ]
            },

            {
                id       : 'profile',
                title    : 'Perfil',
                translate: 'Perfil',
                type     : 'item',
                icon     : 'supervised_user_circle',
                url      : '/apps/profile'
            },

            {
                id       : 'system-support',
                title    : 'Suporte do Sistema',
                translate: 'Suporte do Sistema',
                type     : 'item',
                icon     : 'supervised_user_circle',
                url      : '/apps/system-support'
            }
        ]
    }
];

export const navigation_select_company: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : '',
        translate: '',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id       : 'investors',
                title    : 'Selecione uma empresa',
                translate: 'Selecione uma empresa',
                type     : 'item',
                icon     : 'today',
                url      : '/apps/select-company'
            },
            {
                id       : 'brands',
                title    : 'Marcas',
                translate: 'Marcas',
                type     : 'item',
                icon     : 'home',
                url      : '/apps/brands'
            }
        ]
        
    }
];

export const navigation_select_company_seller: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : '',
        translate: '',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id       : 'investors',
                title    : 'Selecione uma empresa',
                translate: 'Selecione uma empresa',
                type     : 'item',
                icon     : 'today',
                url      : '/apps/select-company'
            }
        ]
        
    }
];

