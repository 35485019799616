import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { SigninModel } from '../model/signin.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public token: string;
  selected_company: any;

  constructor(private http: HttpClient,
    private router: Router,
    private _matSnackBar: MatSnackBar,
    private _fuseNavigationService: FuseNavigationService,
  ) {
    this.checkUser();

  }

  checkUser() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
      this.token = currentUser.access_token;
      this.selected_company = currentUser.selected_company;
      if (this.token) {
        let role = currentUser.user.role;
        let selected_company = currentUser.selected_company;
        if (selected_company > 0) {
          if (role == 'admin') {
            this._fuseNavigationService.setCurrentNavigation('admin');
          } else if (role == 'seller') {
            this._fuseNavigationService.setCurrentNavigation('seller');
          }
          this.router.navigate(['/apps/negotiations/all']);
          // this.router.navigate(['/ui/forms-negotiations']);
        } else {
          this.router.navigate(['/apps/select-company']);
        }
      }
    } else {
      this.router.navigate(['/pages/auth/login']);
    }
  }

  signin(data_login): Promise<Object | String | void> {
    console.log(data_login);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return this.http.post(`${environment.api}/auth/login`, data_login, { headers: headers })
      .toPromise()
      .then((user) => {
        console.log(user);
        let data = user;
        if (data['access_token']) {
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.checkUser();
          this.presentToast('Bem vindo');
        }
        return 'error';
      }).catch((error) => {
        this.presentToast('Usuário não encontrado');
        return 'error';
      })
  }

  get(name: string, newheaderName = '1', newheaderValue = '1'): Observable<any[]> {

    let token = this.token;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8', "Authorization": ["Bearer " + token], "BrandSelected": [this.selected_company], [newheaderName]: [newheaderValue] });

    return this.http.get<any[]>(`${environment.api}/${name}`, { headers: headers });
  }

  logout(): void {
    localStorage.clear();
    this.router.navigate(['/pages/auth/login']);
  }

  setUser(): Promise<boolean> {
    return this.http.get<any>(`${environment.api}/auth/me`)
      .toPromise()
      .then(data => {
        if (data.user) {
          // localStorage.setItem('user', btoa(JSON.stringify(data.user)));
          return true;
        }
        return false;
      });
  }

  presentToast(message) {
    this._matSnackBar.open(message, '', { duration: 2500 });
  }
}
